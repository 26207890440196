import React, { useEffect, useState, useCallback, useRef } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { connect } from "react-redux"
import { getProperties } from "../actions/propertiesAction"
import {
  clearPropertyFilters,
  setGeneralModal,
  setLoading,
} from "../actions/settingsActions"
import CloseFullScreen from "../assets/icons/closeFullScreen"
import FilterIcon from "../assets/icons/filterIcon"
import OpenFullScreen from "../assets/icons/openFullScreen"
import AdvancedFilters from "../components/filter/AdvancedFilters"
import AdvancedFiltersModal from "../components/filter/advancedFiltersModal"
import usePropertyFilters from "../components/hooksComponents/usePropertyFilters"
import Layout from "../components/layout"
import LoaderComponent from "../components/loader"
import Seo from "../components/seo"
import ClearFilterIcon from "../assets/icons/clearFilter"
import PropertyGrid from "../components/propertyGrid"
import SortComponent from "../components/sortComponent"
import { useInfiniteLoading } from "../components/hooksComponents/useInfiniteLoading"
import dataApi from "../_api/dataApi"
import noProps from "../assets/images/no-props.png"
import { error, scrollTop } from "../utils"
import Button from "../components/button"
import ViewModeButtons from "../components/viewModeButtons"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"
import modernHome from "../assets/images/modernHomeForSale.svg"

const Properties = ({
  setGeneralModal,
  loading,
  clearPropertyFilters,
  lang,
  sort,
  getProperties,
  setLoading,
}) => {
  const isMobile = useMediaQuery("(max-width: 528px)")
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const [total, setTotal] = useState(0)
  const toggleSidebarExpanded = () => setSidebarExpanded(!sidebarExpanded)
  const location = useLocation()
  const { for: forTitle } = parse(location.search)

  const isSidebarHidden = useMediaQuery(`(max-width: 992px)`)
  const setVertical = useMediaQuery(`(max-width: 1600px)`)

  let pageTitle = (
    <FormattedMessage
      defaultMessage="Search Results for {search}"
      values={{
        search: "",
      }}
    />
  )

  if (forTitle === "rent") {
    pageTitle = <FormattedMessage defaultMessage="Properties For Rent" />
  } else if (forTitle === "sale") {
    pageTitle = <FormattedMessage defaultMessage="Properties For Sale" />
  }

  const filtersModalHandler = () => {
    setGeneralModal({
      open: true,
      Component: AdvancedFiltersModal,
      modalClassName: "full-modal",
      componentProps: { onApplyFilters: () => setReload(true) },
    })
  }

  const apiFilters = usePropertyFilters()

  const fetchProperties = useCallback(
    (config = {}) => {
      if (!config?.page || config?.page === 1) {
        setLoading(true)
      }
      return dataApi
        .getProperties({ ...config, filters: apiFilters, sort })
        .then(res => {
          setTotal(res?.total || 0)
          return Promise.resolve(res)
        })
        .catch(() => {
          error()
          return Promise.resolve(null)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [apiFilters, sort, getProperties, setLoading]
  )

  const { items, loadMoreRef, setReload, hasNext, loadNext } =
    useInfiniteLoading({
      getItems: fetchProperties,
      options: {
        loadingType: "infinite",
      },
    })

  useEffect(() => {
    !isSidebarHidden && setReload(true)
    scrollTop()
  }, [apiFilters, sort, lang])

  useEffect(() => {
    return () => clearPropertyFilters()
  }, [])

  return (
    <Layout>
      <Seo title="Properties" />
      {loading && <LoaderComponent />}
      <div className="w-100 greyXLightBg d-flex">
        <div
          className="whiteBg shadow py-5 d-none d-lg-block advanced-filter__sidebar"
          style={{
            width: sidebarExpanded ? "100%" : "425px",
          }}
        >
          <aside
            className="__content"
            style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
          >
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="px20 black font-bold mb-0">
                <FormattedMessage defaultMessage="Advanced Filter" />
              </h4>
              {/* <div className="d-flex space-x-8">
                <button
                  className="hover-button-primary"
                  onClick={clearPropertyFilters}
                >
                  <ClearFilterIcon />
                </button>
                <button onClick={toggleSidebarExpanded}>
                  {sidebarExpanded ? <CloseFullScreen /> : <OpenFullScreen />}
                </button>
              </div> */}
            </div>
            <AdvancedFilters />
          </aside>
        </div>
        <div
          className="p-3 p-lg-5 flex-1"
          style={{ minWidth: "min(500px, 100%)" }}
        >
          {total <= 0 && !loading && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
              <img className="w-50" src={noProps} alt="No Properties" />
              <div className="text-decorated mt-3">
                <h2 className="px36 black font-600">
                  <FormattedMessage defaultMessage="Opps No search Results" />
                </h2>
                <p className="px20 black mb-0">
                  <FormattedMessage defaultMessage="We did not find any search results" />
                </p>
              </div>
              <div className="d-block d-lg-none mt-2">
                <Button color="secondary" onClick={filtersModalHandler}>
                  <FormattedMessage defaultMessage="Try Again" />
                </Button>
              </div>
            </div>
          )}
          {total > 0 && (
            <>
              <div
                className="d-flex align-items-center justify-content-between mb-2 flex-wrap"
                style={{ padding: "20px" }}
              >
                <div className="text-decorated">
                  <h2 className="px36 black font-600">{pageTitle}</h2>
                  <p className="px20 black mb-0">
                    {forTitle ? (
                      <FormattedMessage
                        defaultMessage="{count} Items"
                        values={{ count: total }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="{count} Search results"
                        values={{ count: total }}
                      />
                    )}
                  </p>
                </div>

                <div
                  className="d-flex justify-content-end flex-grow-1 flex-lg-grow-0 space-x-8 py-4 "
                  style={{ gap: "20px" }}
                >
                  <button
                    className="px28 px-3 darkGrey2 d-inline d-lg-none"
                    onClick={filtersModalHandler}
                  >
                    <FilterIcon />
                  </button>
                  {(!setVertical) && <ViewModeButtons />}
                  <SortComponent />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "16px",
                  paddingTop: "40px",
                  padding: "20px",
                }}
              >
                <img
                  alt="modern home"
                  src={modernHome}
                  width="100%"
                  height="auto"
                  class={`br16 shadow-lg ${
                    isMobile ? "smallPropertiesImg" : null
                  }`}
                />
              </div>
              <div
                className="pb-5"
                style={{
                  padding: isSidebarHidden
                    ? "10px"
                    : setVertical
                    ? "20px"
                    : "40px",
                }}
              >
                <PropertyGrid
                  forceVertical={isSidebarHidden || setVertical}
                  properties={items}
                  minimized={sidebarExpanded}
                />
                {hasNext && !loading && (
                  <button
                    ref={loadMoreRef}
                    className="w-100 px24 font-bold p-2 secondaryBg white br8"
                    onClick={() => loadNext()}
                  >
                    <FormattedMessage defaultMessage="Load More" />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({
  data: { properties },
  settings: { lang, sort },
  shared: { loading },
}) => ({
  properties,
  loading,
  lang,
  sort,
})
export default connect(mapStateToProps, {
  clearPropertyFilters,
  setGeneralModal,
  getProperties,
  setLoading,
})(Properties)
